import { AppliedFilterInterface, ChoiceList, FilterInterface, Select } from '@shopify/polaris'
import { useCallback } from 'react'

import { splitOptionalString } from '../../common/helpers'
import { SubscriptionFilter } from '../api'

type PauseReason =
  | ''
  | 'default'
  | 'low_inventory'
  | 'shop_disabled'
  | 'contract_under_review'
  | 'invalid_line_items'
  | 'failed_payments'

const pauseReasonLabels: Record<PauseReason, string> = {
  '': 'Any reason',
  default: 'Paused manualy',
  low_inventory: 'Low inventory',
  contract_under_review: 'Contract under review',
  shop_disabled: "Merchant's pricing plan deactivated",
  invalid_line_items: 'Invalid line items',
  failed_payments: 'Failed payments',
}

export const useAddStatusFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const selectedStatuses = splitOptionalString(filter.status)

  const selectedStatusesLabel = () => {
    const pauseReasonLabel =
      filter.pause_reason && filter.pause_reason !== ''
        ? ' (' + pauseReasonLabels[filter.pause_reason as PauseReason] + ')'
        : ''
    return selectedStatuses
      .map((status) => (status === 'paused' ? status + pauseReasonLabel : status))
      .join(' or ')
  }

  // Reset page to 1 when status changes
  const setStatuses = useCallback(
    (sel: string[]) => {
      const status = sel.length > 0 ? sel.join(',') : undefined
      const pause_reason = sel.includes('paused') ? filter.pause_reason ?? undefined : undefined

      updateFilter({ status, pause_reason })
    },
    [updateFilter, filter]
  )

  const clearStatuses = useCallback(() => setStatuses([]), [setStatuses])

  const pauseReasonOptions = Object.keys(pauseReasonLabels).map((value) => ({
    label: pauseReasonLabels[value as PauseReason],
    value: value,
  }))

  const renderPauseReasonOptions = useCallback(
    (selected: boolean) =>
      selected && (
        <Select
          label="Pause trigger:"
          labelHidden
          options={pauseReasonOptions}
          value={filter.pause_reason ?? ''}
          onChange={(val) => updateFilter({ pause_reason: val })}
        />
      ),
    [updateFilter, filter, pauseReasonOptions]
  )

  const statusChoices = [
    { value: 'inactive', label: 'Inactive' },
    { value: 'active', label: 'Active' },
    { value: 'paused', label: 'Paused', renderChildren: renderPauseReasonOptions },
    { value: 'expired', label: 'Expired' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'failed', label: 'Failed' },
  ]

  filters.push({
    key: 'status',
    label: 'Status',
    filter: (
      <ChoiceList
        title="Status"
        titleHidden
        choices={statusChoices}
        selected={selectedStatuses}
        onChange={setStatuses}
        allowMultiple
      />
    ),
    shortcut: true,
  })

  if (selectedStatuses.length > 0) {
    appliedFilters.push({
      key: 'status',
      label: `Status: ${selectedStatusesLabel()}`,
      onRemove: clearStatuses,
    })
  }
}
